import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/common/navbar/navbar";
import Footer from "./components/common/footer/footer";
import Loader from "./loader";
import GoToTop from "./goToTop";
const Home = lazy(() => import("./components/home/home"));
const Contact = lazy(() => import("./components/contact/contact"));
const HorizonRice = lazy(() =>
  import("./components/horizon_rices/horizonRices")
);
const HorizonSpices = lazy(() =>
  import("./components/horizon_spices/horizonSpices")
);
const HorizonSalts = lazy(() =>
  import("./components/horizon_salts/horizonSalts")
);
const HorizonSupure = lazy(() =>
  import("./components/horizon_supure/horizonSupure")
);

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-products/horizon-rice" element={<HorizonRice />} />
        <Route
          path="/our-products/horizon-spices"
          element={<HorizonSpices />}
        />
        <Route path="/our-products/horizon-salts" element={<HorizonSalts />} />
        <Route
          path="/our-products/horizon-supure"
          element={<HorizonSupure />}
        />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <GoToTop />
    </Suspense>
  );
};

export default App;
