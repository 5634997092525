import { Container, Grid2 } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo/Horizon Traders Logo.jpg";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <img
              src={Logo}
              alt="Horizon Traders"
              width={150}
              height={150}
              style={{
                borderRadius: ".3rem",
              }}
            />
            <h1>Horizon Traders</h1>
            <p>
              Horizon Traders, founded in 2010 in Punjab, Pakistan, thrives on
              excellence, leveraging the region’s fertile soil and ideal
              climate.
            </p>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <h1>Quick Links</h1>
            <Link to="/" className="link">
              Home
            </Link>
            {/* <Link to='/'>About Us</Link> */}
            <Link to="/contact" className="link">
              Contact Us
            </Link>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <h1>Get In Touch</h1>
            <p>
              <i className="fas fa-map-marker-alt"></i>
              House # 02, Azhar Colony, Khara Road, Kasur
            </p>

            <p>
              <i className="fas fa-envelope"></i>
              <a href="mailto:info@horizon-traders.com">
                info@horizon-traders.com
              </a>
            </p>

            <p>
              <i className="fas fa-phone"></i>
              <a href="tel:+923064454030">+92-306-4454030</a>
            </p>

            <p>
              <i className="fas fa-phone"></i>
              <a href="tel:+923334651110">+92-333-4651110</a>
            </p>
          </Grid2>
        </Grid2>

        <center>
          <p className="copyright">
            &copy; Horizon Traders. All Rights Reserved.
          </p>
        </center>
      </Container>
    </footer>
  );
};

export default Footer;
