import React from "react";
import Top from "./top/top";
import HT from "./ht/ht";
import Nav from "./nav/nav";

const Navbar = () => {
  return (
    <header>
      <Top />
      <HT />
      <Nav />
    </header>
  );
};

export default Navbar;
