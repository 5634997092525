import { Container } from "@mui/material";
import React from "react";

const Top = () => {
  return (
    <Container className="top">
      <center>
        <p className="arabic">بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيْمِ</p>
      </center>
    </Container>
  );
};

export default Top;
