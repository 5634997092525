import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import useDetectScroll from "@smakss/react-scroll-direction";

const GoToTop = () => {
  const [display, setDisplay] = useState("none");

  const { scrollPosition } = useDetectScroll();

  useEffect(() => {
    if (scrollPosition.top > 0) setDisplay("block");
    else setDisplay("none");
  }, [scrollPosition.top]);

  return (
    <div className="go-to-top" style={{ display: display }}>
      <IconButton
        onClick={() =>
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 300)
        }
        sx={{ p: 0, m: 0, height: "100%", width: "100%" }}
      >
        <div className="fas fa-arrow-up"></div>
      </IconButton>
    </div>
  );
};

export default GoToTop;
