import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/logo/Horizon Traders Full Logo.jpg";

const Nav = () => {
  const [check, setChecked] = useState(false);

  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
    setChecked(false);
  };

  return (
    <nav>
      <div className="nav">
        <h3 className="mob-head">Horizon Traders</h3>
        <label htmlFor="nav">
          <i className="fas fa-bars"></i>
        </label>

        <input
          type="checkbox"
          id="nav"
          checked={check}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <ul className="nav-ul">
          <li className="close">
            <label htmlFor="nav">
              <i className="fas fa-close"></i>
            </label>
          </li>
          <li className="img-li">
            <img
              src={Logo}
              alt="Horizon Traders"
              style={{
                maxWidth: 300,
                width: "100%",
              }}
            />
          </li>
          <li onClick={() => handleRoute("/")}>Home</li>
          <li className="sub-nav-li">
            Our Products <i className="fas fa-angle-down"></i>
            <ul className="sub-nav">
              <li onClick={() => handleRoute("/our-products/horizon-rice")}>
                Horizon Rices
              </li>
              <li onClick={() => handleRoute("/our-products/horizon-spices")}>
                Horizon Spices
              </li>
              <li onClick={() => handleRoute("/our-products/horizon-salts")}>
                Horizon Salts
              </li>
              <li onClick={() => handleRoute("/our-products/horizon-supure")}>
                Horizon Supure
              </li>
            </ul>
          </li>
          <li onClick={() => handleRoute("/contact")}>Contact</li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
