import React from "react";
import { Container, Tooltip } from "@mui/material";
import Logo from "../../../../assets/logo/Horizon Traders Logo.jpg";

const HT = () => {
  return (
    <div className="ht">
      <Container className="con">
        <div>
          <i className="fas fa-phone"></i>
          &nbsp;<a href="tel:+923064454030">+92-306-4454030</a>
        </div>

        <div className="heading">
          <img src={Logo} alt="" width={50} height={50} />
          <h1>Horizon Traders</h1>
        </div>
        <div className="social-media">
          <Tooltip title="Coming Soon">
            <a href="/">
              <i className="fab fa-facebook-f"></i>
            </a>
          </Tooltip>
          <Tooltip title="Coming Soon">
            <a href="/">
              <i className="fab fa-instagram"></i>
            </a>
          </Tooltip>
        </div>
      </Container>
    </div>
  );
};

export default HT;
